<template>
  <div class="feedback">
    <div class="feedback__wrapper">
      <h2 class="feedback__title">Обратная связь</h2>
      <p class="feedback__p">Обратная связь</p>
      <div class="input">
        <label class="input__label"
               for="name">Как к Вам обращаться?</label>
        <input class="input__input"
               id="name"
               type="text">
      </div>
      <div class="input">
        <label class="input__label"
               for="email">Электронная почта</label>
        <input class="input__input"
               id="email"
               type="text">
      </div>
      <div class="textarea">
        <label class="textarea__label"
               for="message">Сообщение</label>
        <textarea class="textarea__textarea"
                  id="message"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feedback',
};
</script>
